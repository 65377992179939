import { ref, computed, watch, onMounted } from 'vue'
import { toastController } from '@ionic/vue'
import { param } from './tools.js'
import store from '../store/index.js'
import router from '../router/index.js'
import { _locale } from '../WiseEcom/services/WiseLocale.js'
import { wiseAlert } from '../WiseEcom/services/WiseAlert.js'
import settings from '../WiseSettings.js'

import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { FCM } from '@capacitor-community/fcm'
import { PushNotifications } from '@capacitor/push-notifications'
import axios from 'axios'

/**
 * All values in the payload are type of String, even when you
 * expect them to be Integer etc. So avoid exact value comparisons.
 * 
 * @typedef {Object} NotificationPayload
 * @property {String} title
 * @property {String} body
 * @property {String} messageType
 * @property {String} articleid
 * @property {String} clubId
 */

const { hosts } = settings
const MESSAGE_TYPE = {
	matchLive: 1,
	normal: 2,
	article: 3,
	link: 4,
	survey: 5,
	event: 7,
	// Golf spesific 60+
	teetimeConfirmationReminder: 60,
	teetimeChange: 61,
}

const notificationListenersRegistered = ref(false)
const notificationsEnabled = ref(true)
const notificationsTopics = ref(settings.topicDefaults)
const notificationsToken = ref('')
const permissionStatus = ref(null)

const loggedIn = computed(() => store.getters['user/loggedIn'])
const userLocale = computed(() => store.getters['common/userLocale'])
const topicsForUserLocale = computed(() => {
    const topics = Object.fromEntries(
        Object.entries(settings.topics).filter(([key]) => key.includes('-' + userLocale.value.split('-')[0]))
    )

    if (Object.keys(topics).length > 0) {
        return topics
    }

    return null
})
const notificationsSettings = computed(() => ({
    enabled: notificationsEnabled.value,
    token: notificationsToken.value,
    topics: notificationsTopics.value
}))

function setNotificationsEnabled(value) {
    notificationsEnabled.value = value;
    Preferences.set({ key: `notifications-settings-${ settings.appId }`, value: JSON.stringify(notificationsSettings.value) })
}

// Used when user toggle topic enable/disable
function setTopicEnabled(topic) {
    notificationsTopics.value[topic.key] = topic.enabled
    // Update values in localStorage
    Preferences.set({ key: `notifications-settings-${ settings.appId }`, value: JSON.stringify(notificationsSettings.value) })
}

function setDefaultTopics(topics) {
    notificationsTopics.value = topics;
    Preferences.set({ key: `notifications-settings-${ settings.appId }`, value: JSON.stringify(notificationsSettings.value) })
}

function setFirebaseToken(token) {
    notificationsToken.value = token
    // Update values in localStorage
    Preferences.set({ key: `notifications-settings-${ settings.appId }`, value: JSON.stringify(notificationsSettings.value) })
}

// Used when found notification settings from localStorage
function setNotificationSettings({
    enabled,
    token,
    topics
}) {
    notificationsEnabled.value = enabled
    notificationsToken.value = token
    notificationsTopics.value = topics
}

async function getNotifications() {
    const activeHost = store.getters['common/activeHost']

    try {
        const { 
            ajaxUrl,
            appauth
        } = activeHost

        const { data } = await axios.post(
            `${ ajaxUrl }?getnotificationmessages=1&lang=${ userLocale.value }&nocache=${ Date.now() }&appauth=${ appauth }`, 
            { 
                topics: Object.keys(notificationsTopics.value), 
                appId: settings.appId 
            }
        )
        
        if (data.notificationMessages) {
            return data.notificationMessages
        }
    } catch (error) {
        console.log('getNotifications error:', error)
    }

    return []
}

async function subscribeTopic(topic) {
    try {
        console.log("FCM subscribe to topic:", topic)
        await FCM.subscribeTo({ topic })
    } catch (error) {
        console.error("Error subscriping to FCM topic:", JSON.stringify(error))
    }
}

async function unsubscribeTopic(topic) {
    try {
        console.log("FCM unsubscribe topic:", topic)
        await FCM.unsubscribeFrom({ topic })
    } catch (error) {
        console.error("Error unsubscriping FCM topic:", JSON.stringify(error))
    }
}

async function getSubscribedTopics() {
    try {
        const { value } = await Preferences.get({ key: `wise-subscribed-topics-${ settings.appId }`})

        if (value !== null) {
            return JSON.parse(value)
        }
        return []
    } catch (error) {
        console.error("Error while fetching/parsing wise-subscribed-topics:", error)
        return []
    }
}

async function unsubscribeAllTopics() {
    // Get currently subscribed topics
    let wiseSubscribedTopics = await getSubscribedTopics();

    // Unsubscribe from all current topics.
    for (const topic of wiseSubscribedTopics) {
        unsubscribeTopic(topic);
    }

    // Save (un)subscribed topics
    await Preferences.set({ key: `wise-subscribed-topics-${ settings.appId }`, value: JSON.stringify([]) });
}

// Unregister push notification, disable all listeners, remove fcm token, unsubscribe from all topics, update state to db
async function unregisterPush() {
    const isMobile = ['ios','android'].includes(Capacitor.getPlatform())
    // Push Notifications only work on device (no browser implementation)
    if (isMobile === false) {
        return;
    }

    try {
        // Remove FCM instance
        //await FCM.deleteInstance()
        PushNotifications.removeAllListeners()
        // Clear FCM token from vuex
        setFirebaseToken('')
        await unsubscribeAllTopics()
        // Update notification settings to DB
        await store.dispatch('user/updateUserSettings', notificationsSettings.value)

        notificationListenersRegistered.value = false;
    } catch (error) {
        console.error("Error on unregisterPush(),", error)
    }
}

// Register push notifications, add listeners, get fcm token, subscribe to topics, update state to db
async function registerPush() {
    const isMobile = ['ios','android'].includes(Capacitor.getPlatform())
    // Push Notifications only work on device (no browser implementation)
    if (isMobile === false) {
        return;
    }
    
    // Check are notifications enabled?
    if (notificationsEnabled.value === false) {
        console.log("Notifications are not enabled, abort registerPush()")
        return
    }

    try {
        let permission = await PushNotifications.checkPermissions()

        if (permission.receive === 'prompt') {
            permission = await PushNotifications.requestPermissions()
        }

        if (permission.receive !== 'granted') {
            console.log('User denied Notification permissions!')
            return;
        }

        await PushNotifications.register()
        
        const { token } = await FCM.getToken()
        console.log('FCM token', token)
        setFirebaseToken(token)

        permissionStatus.value = permission.receive
        
        await registerNotificationListeners()
        await handleTopics()
        await store.dispatch('user/updateUserSettings', notificationsSettings.value)
    } catch (error) {
        console.error("Error on registerPush():", error)
    }
}

async function handleTopics() {
    // Check if we are subscribed to any other topics. (Get subscribed topics from localStorage)
    let wiseSubscribedTopics = await getSubscribedTopics()

    // Unsubscribe from any topic not in settings. This is because of multiple locations potentially causing problems with "ghost" topics.
    // TODO: WiseGolf push, voiko käyttäjä seurata useamman seuran notifikaatioita?
    if (wiseSubscribedTopics && wiseSubscribedTopics.length) {
        for (let i=0;i < wiseSubscribedTopics.length;i++) {
            const subbedTopic = wiseSubscribedTopics[i]

            if (!(subbedTopic in notificationsTopics.value)) {
                // Found foreign topic
                console.log("Found foreign topic '" + subbedTopic + "'. Unsubscribing");

                // Unsubscribe from foreign topic
                unsubscribeTopic(subbedTopic);

                // Remove foreign topic from wiseSubscribedTopics
                wiseSubscribedTopics.splice(i, 1);
            }
        }
    }

    // Subscribe to topics defined in settings.
    for (const [topic, enabled] of Object.entries(notificationsTopics.value)) {
        if (['enabled','token'].includes(topic)) {
            continue;
        }
        // Subscribe only if topic in notifications settings is actually set in current app topics settings. Required for useMultipleLocations
        if (enabled === true && Object.keys(settings.topics).includes(topic)) {
            subscribeTopic(topic)
            // Add topic we just subscribed to into subscribed topics in localStorage (if not yet there)
            if (!wiseSubscribedTopics.includes(topic)) {
                wiseSubscribedTopics.push(topic)
            }
        } else {
            // Remove from wiseSubscribedTopics
            const index = wiseSubscribedTopics.findIndex((elem) => elem === topic);

            if (index !== -1) {
                unsubscribeTopic(topic)
                wiseSubscribedTopics.splice(index, 1);
            }
        }
    }

    // Save subscribed topics to localStorage
    await Preferences.set({ key: `wise-subscribed-topics-${ settings.appId }`, value: JSON.stringify(wiseSubscribedTopics) });
}

async function registerNotificationListeners() {
    if (notificationListenersRegistered.value) {
        console.log('registerNotificationListeners: listeners already registered')
        return;
    }
    console.log('registerNotificationListeners: register listeners')
    
    /**
     * @param {NotificationPayload} payload 
     */
    const handleProxy = (payload) => {
        if (payload.messageType == MESSAGE_TYPE.teetimeConfirmationReminder) {
            handleReservationReminder(payload)
        } else if (payload.messageType == MESSAGE_TYPE.teetimeChange) {
            handleTeetimeChange(payload)
        } else {
            handlePushnotification(payload, true)
        }
    }
    // Add Notification received (called when app is foreground)
    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
        const payload = notification?.data || null
        
        console.log(`pushNotificationReceived`, notification);
        handleProxy(payload)
    })
    // Add Action performed (called when user tabs notification on device menu)
    await PushNotifications.addListener('pushNotificationActionPerformed', (notificationTab) => {
        const payload = notificationTab?.notification?.data || null

        console.log(`pushNotificationActionPerformed`, notificationTab);
        handleProxy(payload)
    })

    notificationListenersRegistered.value = true;
}

/**
 * @param {NotificationPayload} payload 
 */
async function handleTeetimeChange(payload) {
    const activeHost = store.getters['common/activeHost']
    const { 
        title, 
        body,
        clubId,
        productId,
        resourceId,
        teetime
    } = payload
    const [ hostSettings ] = hosts.filter(row => row.golfClubId == clubId)
    const buttons = [
        {
            side: 'end',
            text: _locale('close'),
            role: 'cancel',
        }
    ]

    buttons.push({
        side: 'end',
        text: _locale('notifications.open'),
        handler: () => {
            if (hostSettings.name === activeHost.name) {
                router.push(`/golf/teetime/${ productId }/${ resourceId }/?date=${ teetime }`)
            } else {
                wiseAlert({
                    mode: 'ios',
                    message: _locale('golfteetimechange.clubchangeWarning'),
                    buttons: [
                        {
                            text: _locale('cancel'),
                        },
                        {
                            text: _locale('notifications.open'),
                            handler: () => {
                                window.forceGolfInit = true
                                router.push(`/golf/teetime/${ productId }/${ resourceId }/?date=${ teetime }&clubId=${ clubId }`)
                            },
                        },
                    ],
                })						
            }
        }
    })

    const toast = await toastController.create({
        header: title,
        message: body,
        position: 'top',
        color: 'primary',
        buttons,
    })
    await toast.present();
}

/**
 * @param {NotificationPayload} payload 
 */
async function handleReservationReminder(payload) {
    const { 
        title, 
        body,
        ownerName,
        //dateTimeStart,
        reservationTimeId,
        //reservation,
    } = payload
    const [ hostSettings ] = hosts.filter(row => row.name === ownerName)
    const buttons = [
        {
            side: 'end',
            text: _locale('close'),
            role: 'cancel',
        },
        {
            side: 'end',
            text: _locale('confirm tee time'),
            handler: async () => {
                await confirmTeeTime(reservationTimeId, hostSettings)
                
                store.commit('res_golf/setReservationsUpdate', Date.now())
            }
        },
    ]

    const toast = await toastController.create({
        header: title,
        message: body,
        position: 'top',
        color: 'primary',
        buttons,
    })
    await toast.present();
}

function confirmTeeTime(reservationTimeId, settings) {
    const params = {};
    let queryString = '';
    
    params.appauth = settings.appauth;

    if (Object.keys(params).length > 0) {
        queryString = '&'+param(params);
    }

    return axios({
        method: 'GET',
        url: settings.ajaxUrl + '?reservations=confirmgolfteetime&reservationtimeid='+reservationTimeId + queryString
    })
}

/**
 * @param {NotificationPayload} pushData 
 * @param {Boolean} appForeground 
 * @returns 
 */
function handlePushnotification(pushData, appForeground = false) {
    const { notificationRedirects } = settings
    const newsRedirect = notificationRedirects?.news || '/today/article/'
    console.log('handlePushnotification(): RUN', pushData, appForeground)
    
    if (pushData.articleid) {
        // HANDLE SITUATION WHEN PUSH CONTAINES KEY -> articleid
        pushNotificationToast(
            pushData.title, 
            pushData.body, 
            `${ newsRedirect }${ pushData.articleid }`
        )
        return;
    }

    pushNotificationToast(
        pushData.title, 
        pushData.body
    )
}

async function pushNotificationToast(title = false, body, target = null, color = "primary") {
    const buttons = target !== null ? 
    [
        {
            side: 'end',
            text: _locale('notifications.open'),
            handler: () => {
                console.log('redirect to', target)
                router.push(target)
            }
        },
        {
            side: 'end',
            text: _locale("close"),
            role: "cancel",
        }
    ] : 
    [
        {
            side: 'end',
            text: 'OK',
            role: "cancel",
        }
    ];

    const toast = await toastController.create({
        header: title ? title : false,
        message: body,
        // duration: 30000,
        position: "top",
        color,
        buttons,
    })
    await toast.present();
}

export function usePushNotifications(settings = { setupWatchers: false }) {
    const { setupWatchers } = settings

    if (setupWatchers) {
        watch(loggedIn, (val) => {
            if (val) {
                registerPush()
            } else {
                unregisterPush()
            }
        })
    
        // When user changes language, set default push topics for selected language
        watch(userLocale, (val) => {
            if (!val) return;
            // Check we really have topics (When user logs out [userLocale] is empty for a moment, and results no topics, skip this)
            if (topicsForUserLocale.value) {
                setDefaultTopics(topicsForUserLocale.value)
            }
        })
    
        // When user toggles push notifications on/off completely
        watch(notificationsEnabled, (val) => {
            if (val) {
                registerPush()
            } else {
                unregisterPush()
            }
        })
    
        // If user toggles push notification TOPICS on/off
        watch(notificationsTopics, async () => {
            if (loggedIn.value) {
                await handleTopics()
                await store.dispatch('user/updateUserSettings', notificationsSettings.value)
            }
        }, { deep: true })
    }

    onMounted(async () => {
        // Check if we have notification settings in local storage
        const { value } = await Preferences.get({ key: `notifications-settings-${ settings.appId }` });

        // We got notification settings from localStorage
        if (value) {
            console.log("Found notification settings from localStorage:", value)
            
            setNotificationSettings(JSON.parse(value))
        }
    })
    
    return {
        permissionStatus,
        notificationsEnabled,
        notificationsTopics,
        notificationsToken,
        topicsForUserLocale,
        notificationsSettings,
        setNotificationSettings,
        setNotificationsEnabled,
        setDefaultTopics,
        setFirebaseToken,
        setTopicEnabled,
        getNotifications,
        registerPush,
        unregisterPush,
    }
}